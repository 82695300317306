import React, {useContext, useState} from "react";
import MyAccountLayout from "../../components/myAccountLayout";
import gql from "graphql-tag";
import SEO from "../../components/seo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Mutation, Query} from "react-apollo";
import {client, datoClient} from "../../apollo/client";
import {graphql, useStaticQuery} from "gatsby";
import {CircularProgressbar} from "react-circular-progressbar";
import {Field, Formik} from "formik";
import AutoSuggestEnhanced from "../../components/auto-suggest-enhanced";
import {UserContext} from "../../context/UserContext";

const MyLookbook = () => {
  const {user} = useContext(UserContext);
  const [uploading, setUploading] = useState(false)
  const [percentage, setPercentage] = useState(null)
  const [error, setError] = useState(false)
  const [image, setImage] = useState(null)
  const [filter, setFilter] = useState('normal')
  const [cityId, setCityId] = useState(null)
  const [lookbook, setLookbook] = useState(null)
  const citiesData = useStaticQuery(graphql`{
      allDatoCmsCity(sort: {fields: [name]}) {
          edges {
              node {
                  originalId
                  name
                  slug
                  location{
                      longitude
                      latitude
                  }
              }
          }
      }
  }
  `)
  const cities = citiesData.allDatoCmsCity.edges.map(v => v.node)

  const Lookbooks = () => <Query
    query={MY_LOOKBOOKS}
    variables={{userid: user && user.idToken.payload['cognito:username']}}
    client={datoClient}
  >
    {({data, loading, error}) => {
      setUploading(loading)
      setError(error)
      return <div>
        <div className={'mb-8'}>
          <label className='flex justify-center mt-1 cursor-pointer' htmlFor="fileInput">
            <span className="bg-yellow-700 font-title tracking-wide text-white px-4 py-2">Select a file</span>
            <Mutation
              mutation={INSERT_LOOKBOOK_V2}
              client={client}
              onCompleted={({insertLookBookV2: lookbook}) => {
                updateCache(lookbook, user.idToken.payload['cognito:username'])
                setLookbook(lookbook);
              }}
              onError={() => setError(true)}
            >
              {(insertLookbook) =>
                <input type="file" id="fileInput" className='hidden'
                       accept="image/*"
                       onChange={async ({target: {files: [file]}}) => {
                         setError(false)
                         setPercentage(0)

                         const reader = new FileReader()
                         reader.readAsDataURL(file)
                         reader.onload = e => setImage({url: e.target.result})

                         const {data: {datoUploadRequest: {id, url}}} = await client.query({
                           query: DATO_UPLOAD_REQUEST,
                           variables: {input: {filename: file.name}}
                         });
                         const xhr = new XMLHttpRequest()
                         xhr.open('PUT', url)
                         xhr.onload = () => {
                           insertLookbook({variables: {input: {file: {path: id}}}})
                           setPercentage(null)
                         }
                         xhr.onerror = e => {
                           console.error(e)
                           setError(true)
                           setPercentage(null)
                         }
                         xhr.upload.onprogress = e => setPercentage(Math.round(e.loaded / e.total * 100))
                         xhr.send(file)
                       }}
                />
              }
            </Mutation>
          </label>
        </div>
        <div className="flex flex-wrap justify-center w-full">
          {data && data.allLookbooks && data.allLookbooks.map(d => (
            <div key={d.id} className='relative w-auto m-1'>
              <button onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setLookbook(d);
                setImage({url: `${d.image.url}?fit=crop&w=500&h=625`})
              }}>
                <img className={`w-full filter-${d.filter}`} style={{minWidth: 200}}
                     src={`${d.image.url}?fit=crop&w=400&h=500`} alt=""/>
              </button>
              <Mutation
                client={client}
                mutation={DELETE_LOOKBOOK}
                onError={() => setUploading(false)}
                onCompleted={r => {
                  const data = datoClient.readQuery({
                    query: MY_LOOKBOOKS,
                    variables: {userid: user.idToken.payload['cognito:username']}
                  })
                  datoClient.writeQuery({
                    query: MY_LOOKBOOKS,
                    variables: {userid: user.idToken.payload['cognito:username']},
                    data: {allLookbooks: data.allLookbooks.filter(l => l.id !== r.deleteLookBook.id)}
                  })
                  setUploading(false)
                }}
              >{(deleteLookBook) => (
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    // setUploading(true)
                    deleteLookBook({variables: {id: d.id}});
                  }}
                  className='uppercase absolute right-0 top-0 z-50 py-1 px-2 focus:outline-none bg-red-600 font-title font-light text-white text-center tracking-wide text-lg'>
                  Delete
                </button>)
              }</Mutation>

            </div>
          ))}
        </div>
      </div>;
    }
    }
  </Query>

  const updateCache = (lookbook, userid) => {
    const options = {
      query: MY_LOOKBOOKS,
      variables: {userid}
    };
    try {
      const data = datoClient.readQuery(options)
      const existing = data.allLookbooks.find(l => l.id === lookbook.id)
      if (existing) {
        existing.description = lookbook.description
        existing.filter = lookbook.filter
        existing.city = null
        if (lookbook.city) {
          const found = cities.find(c => c.originalId === lookbook.city);
          if (found) {
            existing.city = {
              id: found.originalId,
              name: found.name,
              __typename: "CityRecord"
            }
          }
        }
        datoClient.writeQuery({
          ...options,
          data: {allLookbooks: [...data.allLookbooks]}
        })

      } else {
        datoClient.writeQuery({
          ...options,
          data: {allLookbooks: [lookbook, ...data.allLookbooks]}
        })
      }
    } catch (e) {
    }
  }

  return <MyAccountLayout>
    <SEO title="My LookBook" keywords={[`about us`, `fashion`]}/>
    <div className='flex justify-center mb-16'>
      {uploading ?
        <FontAwesomeIcon className='text-gray-800 my-2' icon={faSpinner} spin={true} size="2x"/> : null}
    </div>
    {error ?
      <div className="font-sans font-simple bg-red-300 p-1 my-1">Something went wrong, please try again later.</div> :
      null
    }
    {image ?
      <div>
        <div className="w-full flex justify-center mb-8">
          <button
            className="bg-yellow-700 font-title tracking-wide text-white px-4 py-2"
            onClick={() => {
              setImage(null)
              setLookbook(null)
            }}>Back
          </button>
        </div>
        <div className="flex flex-wrap mx-2 md:mx-16">
          <div className="relative flex-1">
            <img src={image.url} alt="" className={`filter-${filter}`}/>
            {percentage === null ?
              null :
              <CircularProgressbar value={percentage} text={`${percentage}%`}
                                   className='absolute inset-0 w-20 h-20 my-8'/>}
          </div>
          <div id='lookbook' className="flex-1 flex flex-col mx-2">
            {lookbook && <Mutation
              client={client}
              mutation={UPDATE_LOOKBOOK}
              onCompleted={r => {
                const lookbook = r.updateLookBook;
                updateCache(lookbook, user.idToken.payload['cognito:username'])
                setUploading(false)
                setLookbook(null)
                setImage(null)
              }}
            >
              {(updateLookBook) => (
                <Formik
                  initialValues={{
                    description: lookbook.description,
                    city: lookbook.city ? lookbook.city.name : ''
                  }}
                  validate={values => {
                    let errors = {};
                    // if (!values.description) {
                    //   errors.description = 'Required';
                    // }
                    return errors;
                  }}
                  onSubmit={({description, city}, {setSubmitting}) => {
                    setUploading(true)
                    updateLookBook({variables: {input: {id: lookbook.id, description, city: cityId, filter}}})
                      .then(() => {
                        setSubmitting(false)
                      })
                  }}
                >{({
                     values,
                     errors,
                     touched,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                     isSubmitting,
                     setTouched,
                     setFieldValue
                   }) => (<form className='flex flex-col justify-center' onSubmit={handleSubmit}>
                  <div className="flex flex-col">
                    <AutoSuggestEnhanced
                      id="city"
                      placeholder='Where is the picture taken?'
                      initialValue={values.city}
                      items={cities}
                      onBlur={() => setTouched({...touched, 'city': true})}
                      onSelected={e => setCityId(e && e.originalId)}/>
                    <div className={'text-red-700 text-xs italic ' + (touched.city &&
                    errors.city ? ' ' : ' invisible')}>{errors.city}&nbsp;</div>
                  </div>
                  <div className="flex flex-col">
                    <Field
                      component={'textarea'}
                      className='border-solid border-2 border-black p-2 focus:outline-none w-full'
                      name="description"
                      rows={4}
                      cols={80}
                      placeholder='Description'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                    <p className="text-red-700 text-xs italic">
                      {errors.description && touched.description && errors.description}
                    </p>
                  </div>
                  <div className="flex flex-wrap my-1 justify-center">
                    {['default',
                      '1977',
                      'aden',
                      'amaro',
                      'ashby',
                      'brannan',
                      'brooklyn',
                      'charmes',
                      'clarendon',
                      'crema',
                      'dogpatch',
                      'earlybird',
                      'gingham',
                      'ginza',
                      'hefe',
                      'helena',
                      'hudson',
                      'inkwell',
                      'kelvin',
                      'juno',
                      'lark',
                      'lofi',
                      'ludwig',
                      'maven',
                      'mayfair',
                      'moon',
                      'nashville',
                      'perpetua',
                      'poprocket',
                      'reyes',
                      'rise',
                      'sierra',
                      'skyline',
                      'slumber',
                      'stinson',
                      'sutro',
                      'toaster',
                      'valencia',
                      'vesper',
                      'walden',
                      'willow',
                    ].map(f => (
                      <button
                        key={f}
                        onClick={e => {
                          e.preventDefault()
                          return setFilter(f);
                        }}
                        className='m-1 flex flex-col'
                        // style={{lineHeight: 0}}
                      >
                        <img className={`filter-${f}`} src={`${lookbook.image.url}?fit=crop&w=100&h=125`}
                             alt=""/>
                        <div className="font-title font-light  text-gray-700 text-sm">{f}</div>
                      </button>))}

                  </div>
                  <button className='bg-black text-white  font-title tracking-wide px-4 py-2 mt-2'
                          type="submit"
                          disabled={isSubmitting}>Update
                  </button>
                </form>)}
                </Formik>)}
            </Mutation>
            }
          </div>
        </div>
      </div>
      :
      <Lookbooks/>}
  </MyAccountLayout>
    ;
}


export default MyLookbook

const DATO_UPLOAD_REQUEST = gql`
    query DatoUploadRequest($input: DatoUploadRequestInput!) {
        datoUploadRequest(input: $input) {
            id
            url
        }
    }
`

export const MY_LOOKBOOKS = gql`
    query MyLookBooks($userid: String!) {
        allLookbooks(filter: {userid: {eq: $userid}},orderBy:[createdAt_DESC]) {
            id
            email
            givenName
            familyName
            description
            city {
                id
                name
            }
            filter
            image{
                url
            }
        }
    }
`

const DELETE_LOOKBOOK = gql`
    mutation deleteLookBook($id: ID!) {
        deleteLookBook(input:{id: $id}){
            id
            description
        }
    }
`

const UPDATE_LOOKBOOK = gql`
    mutation updateLookBook($input: UpdateLookBookInput!) {
        updateLookBook(input: $input){
            id
            userID
            email
            givenName
            familyName
            description
            city
            filter
            image {
                url
            }
        }
    }
`

const INSERT_LOOKBOOK_V2 = gql`
    mutation insertLookBookV2($input: InsertLookBookInputV2!) {
        insertLookBookV2(input:$input) {
            id
            userID
            email
            givenName
            familyName
            description
            filter
            image{
                url
            }
        }
    }
`;


